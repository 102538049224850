import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 115px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 30px;
  gap: 22px;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 50px);
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-left: -24px;
    margin-top: -7px;
    padding: 0 10px 17px 24px;
    width: calc(100% + 83px);
    border-bottom: 1px solid var(--escala-de-cinza-10, #efefef);
  }
`;

interface ButtonProps {
  variant: 'primary' | 'danger' | 'invisible' | 'success';
  width?: string;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  width: ${({ width }) => width || 'fit-content'};
  height: 36px;
  padding: var(--spacing-2, 8px) var(--spacing-3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1, 4px);

  border-radius: var(--radius-md, 8px);
  border: ${({ variant }) =>
    variant === 'invisible' ? '1px solid var(--escala-de-cinza-20, #d6d6d6)' : 'none'};
  background: ${({ variant }) => {
    if (variant === 'primary') return 'var(--Primary-Purple, #5768FF)';
    if (variant === 'danger') return 'var(--Mensagens-Danger, #EE3C3E)';
    if (variant === 'success') return 'var(--Mensagens-Success, #62CF7A)';
    return 'none';
  }};

  box-shadow: 0px 1px 2px 0px rgba(18, 18, 23, 0.05);

  cursor: pointer;

  color: ${({ variant }) =>
    variant === 'invisible'
      ? 'var(--escala-de-cinza-90, #101010)'
      : 'var(--escala-de-cinza-0, #FFF)'};

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.42px;

  transition: all 0.5s ease;

  &:hover {
    filter: opacity(0.85);
  }

  &:disabled {
    cursor: default;
    filter: opacity(0.5);

    &:hover {
      filter: opacity(0.5);
    }
  }
`;

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 36px;
  height: 36px;

  background: none;
  border: none;

  cursor: pointer;

  transition: all 0.5s ease;

  &:hover {
    filter: opacity(0.85);
  }
`;

interface TitleProps {
  width?: string;
  margin?: string;
  textAlign?: string;
}

export const Title = styled.h2<TitleProps>`
  width: ${({ width }) => width || '100%'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  color: var(--escala-de-cinza-100, var(--color-surface-Dark-0, #000));

  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin: ${({ margin }) => margin || '0'};

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const PanelArea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);

  padding: 20px 24px;

  background: var(--escala-de-cinza-0, #fff);
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    border-radius: 0;
    padding: 0;
    background: none;
    box-shadow: none;
  }
`;
export const InformationsItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 24px;
  margin-bottom: auto;

  @media screen and (max-width: 768px) {
    gap: 0;
    margin-top: 0;

    margin-left: -24px;

    > div {
      width: calc(100% + 53px);
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--escala-de-cinza-10, #efefef);
`;

export const FinishLectureMessage = styled.p`
  color: var(--escala-de-cinza-90, #101010);
  text-align: center;

  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.36px;

  width: 100%;
  text-align: center;

  > span {
    color: #7398f1;
  }
`;
