import React from 'react';
import { ButtonCloseResolution, QuestionsInformationsArea, TextContent } from './styles';
import { Title } from 'pages/lectures/styles';
import { QuestionInformationsType } from 'pages/lectures/utils/types';
import timesBlackIcon from 'img/times-black.svg';

export function QuestionsInformations({
  handleClickCloseInformationQuestionArea,
  questionInformations,
}: QuestionsInformationsProps) {
  return Object.keys(questionInformations).length > 0 ? (
    <QuestionsInformationsArea data-testid="questioninformations-questions-informations-area">
      <ButtonCloseResolution>
        <img
          data-testid="questioninformations-close-button"
          src={timesBlackIcon}
          alt="Fechar"
          width={30}
          onClick={handleClickCloseInformationQuestionArea}
        />
      </ButtonCloseResolution>
      <Title textAlign="left" data-testid="questioninformations-question-title">
        {questionInformations.title}
      </Title>
      {questionInformations.type === 'video' ? (
        <div
          data-testid="questioninformations-question-content-video"
          dangerouslySetInnerHTML={{ __html: questionInformations.content.trim() }}
        />
      ) : (
        <TextContent data-testid="questioninformations-question-content-text">
          {questionInformations.content}
        </TextContent>
      )}
    </QuestionsInformationsArea>
  ) : null;
}

interface QuestionsInformationsProps {
  questionInformations: QuestionInformationsType;
  handleClickCloseInformationQuestionArea: () => void;
}
