import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import {CourseContainer, CourseDetailContainer} from '../../course'
import { QuestionContainer } from '../../qr'
import Podcast from '../../main/podcast/main';
import Cashback from '../cashback';
import User from '../../user';
import Questions from '../../questions';
import notifications from '../../notifications';
import lectures from '../../lectures';


function MainRoutes() {
  return (
    <Switch>
      <Route exact path='/courses' component={CourseContainer}/>
      <Route path="/courses/:courseId/:courseClassId?" component={CourseDetailContainer} />
      <Route path="/lectures/:courseId/:courseClassId/:testId" component={lectures} />
      <Route path="/lectures/:courseId/:courseClassId?" component={lectures} />
      <Route path="/qr/:questionCode" component={QuestionContainer} />
      <Route path="/podcast" component={Podcast} />
      <Route path="/cashback" component={Cashback} />
      <Route path="/user" component={User} />
      <Route path="/questions" component={Questions} />
      <Route path="/notifications" component={notifications} />
      <Redirect to='/courses' />
    </Switch>
  )
}

export default MainRoutes
