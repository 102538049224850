import styled from 'styled-components';

export const QuestionsInformationsArea = styled.div`
  position: absolute;
  background: #fff;
  width: 100%;
  bottom: 75px;
  left: 0;
  display: flex;
  height: 50%;
  padding: 14px var(--spacing-6, 24px) 20px var(--spacing-6, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid var(--escala-de-cinza-20, #d6d6d6);
  overflow: auto;

  > div {
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    top: -30px;
    left: -30px;
    width: calc(100% + 60px);
    height: calc(100vh - 130px);
    z-index: 1000;
  }
`;

export const ButtonCloseResolution = styled.button`
  position: absolute;
  top: 8px;
  right: 15px;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;

  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const TextContent = styled.p`
  color: var(--escala-de-cinza-80, #252525);

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.42px;
`;
