//eslint-disable-next-line
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import { getQuestionById } from '../../redux/api/question';
import MainLayout from '../../components/MainLayout';
import SearchQuestion from '../../components/SearchQuestion/SearchQuestion';
import Question from '../../components/SearchQuestion/Question';
import HeaderInside from '../../components/HeaderInside';
import { Container, Panel, ButtonsStyled, ButtonStyled, ButtonStyledTwo } from './styles';

function Questions() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState({});

  const fetchData = useCallback(async (id) => {
    const questionData = await getQuestionById(id);
    setData(questionData);
  }, []);

  useEffect(() => {
    if (id) {
      fetchData(id).catch(console.error);
    }
  }, [id, fetchData]);

  return (
    <MainLayout title="Questões" className="home">
      <Container question={!!id}>
        {id ? (
          <ButtonsStyled>
            <ButtonStyled type="default" onClick={() => history.push('/questions')}>
              <ArrowLeftOutlined /> Voltar para Questões
            </ButtonStyled>
            <ButtonStyledTwo type="default" onClick={() => history.push('/questions')}>
              <LeftOutlined style={{ fontSize: '24px' }} />
            </ButtonStyledTwo>
          </ButtonsStyled>
        ) : (
          <HeaderInside
            title="Questões"
            subtitle="Pesquise pelo código, enunciado, módulo, família, capítulo, provas que a pergunta caiu
          e o nível de dificuldade de questões específicas que você deseja fazer. Suas respostas
          não serão salvas no banco de dados e não influenciarão nos seus relatórios :)"
          />
        )}
        <Panel>{id ? <Question data={data} /> : <SearchQuestion />}</Panel>
      </Container>
    </MainLayout>
  );
}

export default Questions;
