import React from 'react';

import { FooterPanel, QuestionsInformationButtonsArea } from './styles';

import ArrowRight from 'img/arrow-right-white.svg';
import ArrowLeft from 'img/arrow-left-white.svg';
import textAlignLeftWhite from 'img/text-align-left-white.svg';
import textAlignLeftGray from 'img/text-align-left-gray.svg';
import playWhite from 'img/play-white.svg';
import playGray from 'img/play-gray.svg';

import { Button } from 'pages/lectures/styles';
import { QuestionType } from 'pages/lectures/utils/types';

export function LecturesFooterPanel({
  questionSelected,
  course,
  isReview,
  handleSelectNextQuestion,
  handleSelectPreviewQuestion,
  handleSetResolutionInformationsText,
  handleSetResolutionInformationsVideo,
  currentLecture,
  handleFinishLecture,
  questionInformations,
}: LecturesFooterPanelProps) {
  const screenWidth = window.innerWidth;

  const disabledPreviewButton = questionSelected?.pivot?.ordem === 1;
  const disabledNextButton =
    currentLecture?.findIndex(
      (question: QuestionType) => question.id_questao === questionSelected.id_questao
    ) ===
    currentLecture?.length - 1;
  const userViewResolutionVideoType =
    Object.keys(questionInformations).length > 0 && questionInformations.type === 'video';
  const userViewResolutionTextType =
    Object.keys(questionInformations).length > 0 && questionInformations.type === 'text';

  return (
    <FooterPanel data-testid="lecturesfooterpanel-footerpanel">
      <Button
        disabled={disabledPreviewButton}
        variant="invisible"
        width={screenWidth <= 768 ? '100%' : '130px'}
        onClick={handleSelectPreviewQuestion}
        style={{ display: screenWidth <= 768 ? 'none' : 'flex' }}
        data-testid="lecturesfooterpanel-prev-button-desktop"
      >
        <img src={ArrowLeft} alt="Anterior" data-testid="lecturesfooterpanel-arrowleft-desktop" />
        Anterior
      </Button>
      <QuestionsInformationButtonsArea data-testid="lecturesfooterpanel-questionsinfo-area">
        <Button
          variant={userViewResolutionTextType ? 'primary' : 'invisible'}
          width={screenWidth <= 768 ? '100%' : '220px'}
          onClick={handleSetResolutionInformationsText}
          disabled={!questionSelected.questao_comentada}
          data-testid="lecturesfooterpanel-readresolution-button"
        >
          <img
            src={userViewResolutionTextType ? textAlignLeftWhite : textAlignLeftGray}
            alt="Ler a resolução"
            data-testid="lecturesfooterpanel-readresolution-icon"
          />
          Ler a resolução
        </Button>
        <Button
          variant={userViewResolutionVideoType ? 'primary' : 'invisible'}
          width={screenWidth <= 768 ? '100%' : '220px'}
          onClick={handleSetResolutionInformationsVideo}
          disabled={
            !questionSelected.video_embed ||
            (course?.is_libras && !questionSelected.video_embed_libras)
          }
          data-testid="lecturesfooterpanel-watchresolution-button"
        >
          <img
            src={userViewResolutionVideoType ? playWhite : playGray}
            alt="Assistir a resolução"
            data-testid="lecturesfooterpanel-watchresolution-icon"
          />
          Assistir a resolução
        </Button>
      </QuestionsInformationButtonsArea>
      <Button
        disabled={disabledPreviewButton}
        variant="invisible"
        width={screenWidth <= 768 ? '100%' : '130px'}
        onClick={handleSelectPreviewQuestion}
        style={{ display: screenWidth <= 768 ? 'flex' : 'none' }}
        data-testid="lecturesfooterpanel-prev-button-mobile"
      >
        <img src={ArrowLeft} alt="Anterior" data-testid="lecturesfooterpanel-arrowleft-mobile" />
        Anterior
      </Button>
      <Button
        variant="primary"
        width={screenWidth <= 768 ? '100%' : '130px'}
        disabled={isReview ? disabledNextButton : false}
        onClick={
          !isReview
            ? disabledNextButton
              ? handleFinishLecture
              : handleSelectNextQuestion
            : handleSelectNextQuestion
        }
        data-testid="lecturesfooterpanel-next-button"
      >
        Próximo
        <img src={ArrowRight} alt="Próximo" data-testid="lecturesfooterpanel-arrowright" />
      </Button>
    </FooterPanel>
  );
}

type LecturesFooterPanelProps = {
  questionSelected: QuestionType;
  course: any;
  isReview: boolean;
  handleSelectNextQuestion: () => void;
  handleSelectPreviewQuestion: () => void;
  handleSetResolutionInformationsVideo: () => void;
  handleSetResolutionInformationsText: () => void;
  questionInformations: any;
  currentLecture: QuestionType[];
  handleFinishLecture: () => void;
};
